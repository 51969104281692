<template>
<div v-loading="loading">
    <h3>Login Shell</h3>
     <el-select v-model="loginShell" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
        >
        </el-option>
    </el-select>
    <h3>姓名</h3>
    <el-input style="width: 17em;" v-model="cn"></el-input>
    <h3>手机号</h3>
    <el-input style="width: 17em;" v-model="phone"></el-input>
    <h3>邮箱</h3>
    <el-input style="width: 17em;" v-model="mail"></el-input>
    <el-divider></el-divider>
    <el-button style="margin-left: 30px;" @click="submit" type="primary">提交</el-button>
</div>
</template>

<script>
import api from "../../api";

export default {
    data: () => ({
        options: [
            "/bin/bash",
            "/bin/zsh",
            "/bin/fish",
            "/bin/sh",
        ],
        loginShell: "",
        loading: false,
        phone: "",
        mail: "",
        cn: ""
    }),
    methods: {
        submit() {
            this.loading = true;
            api.updateMyInfo(this.loginShell, this.phone, this.mail, this.cn).then(res => {
                if (res.code != 0) {
                    this.$message.error(res.message);
                } else {
                    this.$message.success("修改个人信息成功");
                }
                this.loading = false;
            }).catch(err => {
                this.$message.error(err.toString());
                console.error(err);
                this.loading = false;
            });
        }
    },
    mounted() {
        this.loading = true;
        api.getMyInfo().then(res => {
            if (res.code == 0) {
                this.loginShell = res.data.loginShell;
                this.phone = res.data.telephoneNumber;
                this.mail = res.data.mail;
                this.cn = res.data.cn;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch(err => {
            this.$message.error(err.toString());
            console.error(err);
            this.loading = false;
        });
    },
}
</script>